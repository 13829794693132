<template>
	<div class="wrapper" v-loading="loading">
        <div class="top-btn" ref="topBtn">
            <div>
                <el-button size="small" type="primary" @click="add()">添加兑换物</el-button>
            </div>
        </div>
		<el-table :data="list" :max-height="tableHeight" :header-cell-style="{background:'#f5f7fa'}">
			<el-table-column prop="org_id" label="主图">
                <template slot-scope="scope"> 
                   <img :src="scope.row.show_pic || ''" style="width: 50px" alt=""/>
                </template>
            </el-table-column>
			<el-table-column prop="prize_desp" label="兑换奖品设置"></el-table-column>
            <el-table-column prop="cost_score" label="所需积分"></el-table-column>
            <el-table-column prop="org_id" label="详情图片">
                <template slot-scope="scope"> 
                   <img :src="scope.row.detail_pic || ''" style="width: 50px" alt=""/>
                </template>
            </el-table-column>
            <el-table-column prop="org_id" label="状态">
                <template slot-scope="scope">
                   {{ scope.row.status == 1 ? '上架' : '下架' }}
                </template>
            </el-table-column>
            <!-- <el-table-column prop="city_id" label="城市"></el-table-column> -->
            <el-table-column prop="sort" label="排序"></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-dropdown @command="handleCommand">
                        <span class="el-dropdown-link">
                            操作<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :command="{fun: 'handleUp',row: scope.row}">{{ scope.row.status != 1 ? '上架' : '下架' }}</el-dropdown-item>
                            <el-dropdown-item :command="{fun: 'handleEdit',row: scope.row}">编辑</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </el-table-column>
		</el-table>
		<el-pagination v-if="total > 0" style="padding-top: 15px; text-align: center;" @current-change="handleCurrentChange"
		:current-page.sync="currentPage" :page-size="limit" layout="prev, pager, next, jumper" :total="total">
		</el-pagination>
        <PointsPrizeAdd ref="add" :dialogVisible="dialogVisible" :typeFlag="typeFlag" :id="id" @close="close" @getList="getList"></PointsPrizeAdd>
	</div>
</template>
<script>
    import { orgScoreConfList, orgScoreConfSave } from '@/api/institutional'
    import PointsPrizeAdd from './component/pointsPrizeAdd.vue'
	export default {
		name: "List",
		data() {
			return {
                loading: true,
                tableHeight: this.$constant.maxHeight,
                // 列表
                total: 0,
                limit: 20,
                currentPage: 1,
                list: [],
                // 弹框
                dialogVisible: false,
                id: 0,
                typeFlag: 'add',
			}
		},
		components: {
            PointsPrizeAdd
		},
        mounted() {
            this.getList()
            this.tableHeight = this.$common.setHeight([this.$refs.topBtn], 130)
            window.onresize = this.$common.debounce(()=>{
                this.tableHeight = this.$common.setHeight([this.$refs.topBtn], 130)
            }, 1000)
        },
		methods: {
			// 获取列表
			getList() {
                this.loading = true
				orgScoreConfList({
                    limit: this.limit,
                    page: this.currentPage,
                }).then((res) => {
                    this.total = res.data.total;
					this.list = res.data.data;
					this.loading = false;
				}).catch((err) => {
					this.loading = false;
					console.log(err, 222)
				})
            },
            // 页码修改
            handleCurrentChange(val) {
                this.currentPage = val;
                this.getList();
            },
            // 操作回调
            handleCommand(command) {
                this[command.fun](command.row)
            },
            // 新建
            add(){
                this.typeFlag = 'add'
                this.dialogVisible = true
            },
            // 编辑
            handleEdit(row) {
                this.$refs.add.getInfo(row)
                this.id = row.id
                this.typeFlag = 'edit'
                this.dialogVisible = true
            },
            // 上下架
            handleUp(row){
                orgScoreConfSave({
                    id: row.id,
                    status: row.status == 1 ? 0: 1
                }).then(() => {
                    this.$message({
                        message: row.status == 1 ? '下架成功' : '上架成功',
                        type: 'success'
                    });
                    this.getList()
				}).catch((err) => {
					console.log(err, 222)
				})
            },
            // 关闭弹框
            close() {
                this.dialogVisible = false
            },  
		}
	};
</script>
