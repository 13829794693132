<template>
	<div class="dialog">
		<el-dialog :title="typeFlag=='add'?'添加':'修改'" :visible.sync="dialogVisible" width="600px" :before-close="close" :close-on-click-modal='false'>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
                <!-- <el-form-item label="城市id" prop="city_id">
					<CitySelect/>
				</el-form-item> -->
                <el-form-item label="主图" prop="show_pic">
					<Qinniu :imgKey="ruleForm.show_pic" @uploadSuccess='uploadSuccess'/>
				</el-form-item>
                <el-form-item  label="奖品描述" prop="prize_desp">
					<el-input size="small" type="textarea" :rows="2" v-model="ruleForm.prize_desp"></el-input>
				</el-form-item>
				<el-form-item  label="消耗积分" prop="cost_score">
					<el-input type="number" size="small" v-model="ruleForm.cost_score" style="width: 130px"></el-input>
				</el-form-item>
                <el-form-item label="详情图片" prop="detail_pic">
					<Qinniu :imgKey="ruleForm.detail_pic" @uploadSuccess='uploadSuccess2'/>
				</el-form-item>
                <el-form-item label="排序" prop="sort">
					<el-input-number size="small" v-model="ruleForm.sort" :min="1"></el-input-number>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
					<el-button @click="close()">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>
<script>
    import { orgScoreConfSave } from '@/api/institutional'
    // import CitySelect from '@/components/citySelect.vue'
	import Qinniu from '@/components/qiniu.vue'
	export default {
		name: "StkAdd",
		props: {
			dialogVisible: {
				type: Boolean,
				default: false
			},
			typeFlag: { // 判断是新建/add 编辑/edit 详情
				type: String,
				default: 'add'
			},
			id: {
				type: Number,
				default: 0
            },
            row: {
				type: Object,
				default: ()=>{
                    return {}
                }
			}
		},
		components: {
            // CitySelect,
			Qinniu,
		},
		data() {
			return {
				// 弹框新建
				ruleForm: {
                    show_pic: '',
                    prize_desp: '',
                    cost_score: '',
                    detail_pic: '',
                    sort: '',
				},
				rules: {
					show_pic: [
						{ required: true, message: '请选择主图', trigger: 'change' }
                    ],
                    prize_desp: [
						{ required: true, message: '请输入奖品描述', trigger: 'blur' }
                    ],
                    cost_score: [
						{ required: true, message: '请输入消耗积分', trigger: 'blur' }
                    ],
                    detail_pic: [
						{ required: true, message: '请选择详情图片', trigger: 'change' }
                    ],
                    city_id: [
						{ required: true, message: '请选择城市', trigger: 'change' }
                    ],
                    sort: [
						{ required: true, message: '请输入排序', trigger: 'blur' }
					],
				}
			}
		},
		methods: {
			// 获取数据
			getInfo(row) {
                for (let item in this.ruleForm) {
                    this.ruleForm[item] = row[item]
                } 	
			},
			// 创建
			submitForm(formName){
				this.$refs[formName].validate((valid) => {
					if (valid) { this.save() }
				});
			},
			save() {
				let params = { ...this.ruleForm }
				if (this.typeFlag == 'edit') { params.id = this.id }
				orgScoreConfSave(params).then((res) => {
					this.$message({
						message: res.msg,
						type: 'success'
					});
					this.close();
					this.$emit('getList')
				}).catch((err) => {
					this.$emit('close');
					console.log(err, 222)
				})
            },
            // // 选中城市
            // selectCity(id){
            //     this.ruleForm.city_id = id
            // },
			// 关闭
			close() {
				this.$emit('close');
				this.ruleForm = {
					show_pic: '',
                    prize_desp: '',
                    cost_score: '',
                    detail_pic: '',
                    sort: '',
				}
			},
			// 上传图片成功
			uploadSuccess(res) {
				this.ruleForm.show_pic = this.$constant.qnUrl + '/' + res.key
            },
            uploadSuccess2(res) {
				this.ruleForm.detail_pic = this.$constant.qnUrl + '/' + res.key
			},
		}
	};
</script>
